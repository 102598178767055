<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="setting" />
				<span>系统</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 备付金管理 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			
			<a-form class="ant-advanced-search-form" >
				<a-row :gutter="24">
					<a-col :span="6">
						<a-form-model-item label="选择区域：" prop="province">
							<area-select @change="selectArea"  :default-value="searchData.province ? [searchData.province , searchData.city] : []" />
						</a-form-model-item>
					</a-col>
					<a-col :span="4">
						<a-button type="primary" @click="getReserveList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">备付金列表</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showAddEdit('add')">
							新增
						</a-button>
						<a-button type="primary" class="marginr20" @click="showAddEdit('edit')">
							详情
						</a-button>
						<a-button type="primary" class="marginr20" @click="deleteReserve">
							删除
						</a-button>
					</div>
				</div>

				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" :total="total_page * 10"
				 :data-source="ReserveList" rowKey="cr_id" size="small">
					<div slot="block1" slot-scope="text, record">	
					{{ record.province }}{{ record.city }}
				</div>	
				</a-table>
			</div>
		</div>

		<a-modal v-model="addEditShow" :title="isAdd ? '新增备付金' : '编辑备付金'" :footer="null">
			<a-form-model ref="ruleForm" :model="info" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" style="margin: 0 auto; width: 80%">
				<a-row :gutter="24">
					<a-col :span="24">
						<a-form-item label="充值区域：" prop="province">
							<area-s @change="sArea" :default-value="info.province ? [info.province , info.city] : []" />
						</a-form-item>
					</a-col>
					<a-col :span="24">
						<a-form-item label="充值渠道：" prop="pay_type">
							<a-select v-model="info.pay_type" placeholder="请选择充值渠道">
								<a-select-option v-for="(item,index) in paytypeList" :key="index" :value="index+1"> {{item}} </a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="充值金额：" prop="amount">
							<a-input-number v-model="info.amount" :min=0.01  placeholder="请输入充值金额"/>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
			<div class="model-footer">
				<div class="content-btns" v-if="isAdd">
					<a-button type="primary" class="marginr20" html-type="submit" @click="addReserve()">
						确定
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="left" v-if="!isAdd">
					<a-button type="primary" class="marginr20" html-type="submit" @click="updateReserve">
						修改
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="right" v-if="!isAdd" :key="editIndex">
					<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
						上一条
					</a-button>
					<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
						下一条
					</a-button>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import moment from "moment";


	const columns = [
		{
			title: "充值金额(元)",
			dataIndex: "amount",
		},
		{
			title: "充值区域",
			key: 'block1',
			scopedSlots: {
				customRender: 'block1'
			},
		},
		{
			title: "充值渠道",
			dataIndex: "pay_type_txt",
		},
		{
			title: "充值时间",
			dataIndex: "add_time",
		},
	];

	import AreaSelect from "@/components/City3/index.vue";
	import AreaS from "@/components/City4/index.vue";
	import http from '../http'
	export default {
		data() {
			return {
				columns,
				// 编辑与添加
				addEditShow: false,
				// 是否添加状态
				isAdd: false,
				// 搜索
				searchData: {
					province: '',
					city: '',
					page: 1,
				},
				// 备付金列表
				ReserveList: [],
				// 多少条数据 * 10
				total_page: 0,

				// 添加与编辑的数据
				info: {
					province:'',
					city:'',
					amount: 0,
					pay_type: 1
				},
				rules: {
					province: [{
						required: true,
						message: '请选择充值区域',
						trigger: 'change'
					}, ],
					pay_type: [{
						required: true,
						message: '请选择充值渠道',
						trigger: 'change'
					}, ],
					amount: [{
						required: true,
						message: '请输入充值金额',
						trigger: 'blur'
					}, ],
				},
				// 表格选择的数据, 存储的是id
				selectedRowKeys: [],
				// 存储的是数据
				selectedRowDataList: [],
				// 当前是第几条编辑信息
				editIndex: 0,				
				paytypeList: ['支付宝','微信'],
				detailLoading: false
			};
		},
		mounted() {
			this.getReserveList()
		},
		components: {
			AreaSelect,
			AreaS
		},
		methods: {
			moment,
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
				});
			},
			selectArea(selectedArea) {
				this.searchData.province =''
				this.searchData.city =''
				if(selectedArea[0])this.searchData.province = selectedArea[0]
				if(selectedArea[1])this.searchData.city = selectedArea[1]
			},
			//选择地区之后的回调
			sArea(selectedArea) {
				this.info.province = selectedArea[0]
				this.info.city = selectedArea[1]
			},
			handleReset() {
				this.searchData = {
					province: '',
					city: '',
					page: 1,
					amount: 0
				}
				this.getReserveList()
			},
			// 点击上一条 或者 下一条、
			editToData(type, fn) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				if (fn) {
					this.getAuthorityDetail()
				} else {
					this.info = this.selectedRowDataList[this.editIndex]
				}
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
			},
			// 显示 添加与编辑 的 弹窗
			showAddEdit(type) {
				if (type == 'add') {
					// 添加初始数据
					this.info = {
						amount: 0,
						pay_type: 1
					}
					this.isAdd = true
				} else if (type == 'edit') {
					// 判断是否已经选择数据
					if (this.selectedRowKeys.length == 0) {
						this.$message.warning('请先选择备付金');
						return
					}

					let selectDatas = []
					this.ReserveList.forEach((v) => {
						if (this.selectedRowKeys.includes(v.cr_id)) {
							selectDatas.push(v)
						}
					})
					this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
					this.editIndex = 0
					this.info = this.selectedRowDataList[0]
					this.isAdd = false
				}

				this.addEditShow = true
			},
			// 搜索
			search() {
				this.searchData.page = 1
				this.getReserveList()
			},
			// 备付金列表
			getReserveList() {
				http({
					method: 'post',
					url: '/api.corp.reserve.list/',
					data: this.searchData
				}).then((res) => {
					this.ReserveList = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 
			deleteReserve() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择备付金');
					return
				}

				this.$confirm({
					title: '是否删除选中备付金?',
					content: '删除备付金可能会导致设备不能正常使用，确定删除吗？',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						let selectDatas = []
						this.ReserveList.forEach((v) => {
							if (this.selectedRowKeys.includes(v.cr_id)) {
								selectDatas.push(v.cr_id)
							}
						})
						http({
							method: 'post',
							url: '/api.corp.reserve.del/',
							data: {
								cr_id: selectDatas.join(),
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('删除备付金成功');
								this.getReserveList()
							} else {
								this.$message.error(res.message);
							}
						})
					}
				});
			},
			// 添加备付金
			addReserve(type) {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						http({
							method: 'post',
							url: '/api.corp.reserve.add/',
							data: {
								cr_id: 0,
								...this.info
							}
						}).then((res) => {
							if (res.code == '200') {
								if (!type) {
									this.addEditShow = false
								}
								this.$message.success(type == 'edit' ? '修改备付金信息成功' : '添加备付金成功');
								this.getReserveList()
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},
			// 更新备付金
			updateReserve() {
				this.addReserve('edit')
			},
		},
	};
</script>
<style lang="less">
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}

	.Reserveee.tes {
		width: 95%;
		margin: 0 auto;
		border: 1px solid #e8e8e8;
		border-radius: 3px;

		.taes {
			display: flex;
			align-items: stretch !important;

			&>div {
				width: 20%;

				&:nth-child(2) {
					width: 80%;
				}
			}

			.chebox {
				overflow: hidden;
				box-sizing: border-box;
				height: 70px !important;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e8e8e8;

				&:last-child {
					border-bottom: none;
				}

				.chebox-item {
					box-sizing: border-box;
					padding-left: 10px;
				}
			}

			.left {
				min-height: 300px;
				border-right: 1px solid #e8e8e8;

				.chebox {
					height: 100%;
				}
			}

			.contet {
				border-right: 1px solid #e8e8e8;
				height: auto !important; 
			}

			.right {
				min-height: 300px;
			}
		}
	}
</style>